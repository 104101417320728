<template>
  <div>
    <b-card class="d-print-none">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Start Date">
              <b-form-datepicker v-model="selectedStartDate" reset-button/>
            </b-form-group>
            <b-form-group label="End Date">
              <b-form-datepicker v-model="selectedEndDate" reset-button/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update">
              <b-spinner v-if="updating" small></b-spinner>
              <span v-if="!updating">Update Report</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card v-if="Object.keys(reportData).length" class="mt-4">
      <b-row class="mb-2">
        <b-col class="d-flex flex-row" cols="12">
          <h4 class="m-0 flex-fill">Cash Up Report</h4>
        </b-col>
      </b-row>
      <b-row v-if="Object.keys(reportData).length">
        <b-col>
          <hr/>
        </b-col>
      </b-row>
      <b-row v-for="(date, dateInd) in Object.keys(reportData)" :key="`report-date-${dateInd}`">
        <b-col cols="12">
          <b-card class="p-3 mb-3" no-body>
            <b-row>
              <b-col>
                <h5>{{ date }}</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table-simple class="mb-0" responsive sticky-header>
                  <b-thead>
                    <b-tr>
                      <b-th></b-th>
                      <b-th>Payments</b-th>
                      <b-th>Cash In</b-th>
                      <b-th>Refunds</b-th>
                      <b-th>Petty Taken</b-th>
                      <b-th>Expected Out</b-th>
                      <b-th>Actual Out</b-th>
                      <b-th>Float</b-th>
                      <b-th>Discrepancies</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(paymentMethod,
                      paymentMethodInd) in paymentMethods" :key="`table-method-${paymentMethodInd}`" :variant="
                        tableVariationStatus(reportData[date][paymentMethod])
                      ">
                      <b-th>{{ paymentMethod }}</b-th>
                      <b-td>
                        {{
                          reportData[date][paymentMethod].total_payments
                            | currencyFormat
                        }}
                      </b-td>
                      <b-td>
                        {{
                          reportData[date][paymentMethod].cash_in | currencyFormat
                        }}
                      </b-td>
                      <b-td>
                        {{
                          reportData[date][paymentMethod].refunds | currencyFormat
                        }}
                      </b-td>
                      <b-td>
                        {{
                          reportData[date][paymentMethod].petty_taken
                            | currencyFormat
                        }}
                      </b-td>
                      <b-td>
                        {{
                          reportData[date][paymentMethod].expected_out
                            | currencyFormat
                        }}
                      </b-td>
                      <b-td>
                        {{
                          reportData[date][paymentMethod].actual_out
                            | currencyFormat
                        }}
                      </b-td>
                      <b-td v-if="paymentMethod == 'Cash'">
                        {{
                          reportData[date][paymentMethod].float | currencyFormat
                        }}
                      </b-td>
                      <b-td v-else>-</b-td>
                      <b-td>
                        {{
                          getDifference(
                            reportData[date][paymentMethod].actual_out,
                            reportData[date][paymentMethod].expected_out
                          ) | currencyFormat
                        }}
                        <small
                          v-if="reportData[date][paymentMethod].reason"><br>{{ reportData[date][paymentMethod].reason }}</small>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import momentMixin from "../../../mixins/momentMixin";

export default {
  mixins: [momentMixin],
  created() {
    this.selectedStartDate = this.moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    this.selectedEndDate = this.moment().endOf("month").format("YYYY-MM-DD");
  },
  data: () => ({
    paymentMethods: ["Cash", "Cheque", "Debit", "BACS", "Finance"],
    selectedStartDate: null,
    selectedEndDate: null,
    reportData: {},
    updating: false,
  }),
  computed: {
    valid() {
      return Boolean(this.selectedStartDate) && Boolean(this.selectedEndDate);
    },
  },
  methods: {
    tableVariationStatus(data) {
      if (data.actual_out === null) return;
      if (Math.abs(data.actual_out - data.expected_out) === 0) {
        return "success";
      }
      return "danger";
    },
    getDifference(actual, expected) {
      return actual - expected;
    },
    update() {
      if (!this.updating && this.valid) {
        this.updating = true;
        this.reportData = {};

        window.axios
          .post("/reports/cash-up", {
            start_date: this.selectedStartDate,
            end_date: this.selectedEndDate,
          })
          .then((response) => {
            /**
             * The interation below is essentially a filter on the results, so that we do not display
             * results where there is no id on some of the items (this is because if no id then there )
             */
            for (const [dateKey, dateValue] of Object.entries(response.data)) {
              if (
                ["Cash", "Cheque", "Debit", "BACS", "Finance"].some((method) =>
                  Boolean(
                    !Array.isArray(dateValue[method]) && // If the item is not of type 'array' (typeof [] is object)
                    typeof dateValue[method] == "object" && // If type is of object
                    dateValue[method] !== null && // If the item is not null (typeof null is object)
                    dateValue[method].hasOwnProperty("id") // Check if it has an id
                  )
                )
              ) {
                this.reportData[dateKey] = dateValue;
              }
            }

            this.updating = false;
          });
      }
    },
  },
  filters: {
    currencyFormat(v) {
      if (v === null) return " - ";
      return new Intl.NumberFormat("en-gb", {
        style: "currency",
        currency: "GBP",
      }).format(v);
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <b-card class="d-print-none">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Start Date">
              <b-form-datepicker v-model="selectedStartDate" reset-button/>
            </b-form-group>
            <b-form-group label="End Date">
              <b-form-datepicker v-model="selectedEndDate" reset-button/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update">
              <b-spinner v-if="updating" small></b-spinner>
              <span v-if="!updating">Update Report</span></b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card v-if="popularProducts.length" class="mt-4">
      <b-row>
        <b-col cols="12">
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th>Product</b-th>
                <b-th class="text-right">Quantity Sold</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(product, productInd) in popularProducts" :key="productInd">
                <b-td>{{ product.product.name }}</b-td>
                <b-td class="text-right">{{ product.total_quantity }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import momentMixin from "../../../mixins/momentMixin"

export default {
  mixins: [momentMixin],
  created() {
    this.selectedStartDate = this.moment()
      .startOf("month")
      .format("YYYY-MM-DD")
    this.selectedEndDate = this.moment()
      .endOf("month")
      .format("YYYY-MM-DD")
  },
  data: () => ({
    selectedStartDate: null,
    selectedEndDate: null,
    totalProductsSold: 0,
    popularProducts: [],
    order_items: [],
    updating: false,
    total_quantity: null,
  }),
  computed: {
    valid() {
      return Boolean(this.selectedStartDate) && Boolean(this.selectedEndDate)
    }
  },
  methods: {
    update() {
      if (!this.updating && this.valid) {
        this.updating = true
        window.axios
          .post("/reports/popular-products-report", {
            start_date: this.selectedStartDate,
            end_date: this.selectedEndDate
          })
          .then(response => {
            this.popularProducts = response.data.popularProducts
            this.totalProductsSold = response.data.totalProductsSold
            this.updating = false
          })
      }
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>
</style>

<template>
  <div>
    <b-card class="d-print-none">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Start Date">
              <b-form-datepicker v-model="selectedStartDate" reset-button/>
            </b-form-group>
            <b-form-group label="End Date">
              <b-form-datepicker v-model="selectedEndDate" reset-button/>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Payment Method">
              <b-form-select v-model="method">
                <b-form-select-option :value="null">All</b-form-select-option>
                <b-form-select-option value="Cash">Cash</b-form-select-option>
                <b-form-select-option value="Debit">Debit</b-form-select-option>
                <b-form-select-option value="Finance">Finance</b-form-select-option>
                <b-form-select-option value="BACS">BACS</b-form-select-option>
                <b-form-select-option value="Cheque">Cheque</b-form-select-option>
                <b-form-select-option value="Other">Other</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update">
              <b-spinner v-if="updating" small></b-spinner>
              <span v-if="!updating">Update Report</span></b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card v-if="payments.length || refunds.length" class="mt-4">
      <b-row class="mb-2">
        <b-col class="d-flex flex-column flex-fill justify-content-center align-items-start" cols="12" md="8">
          <h4 class="m-0">Payments Report</h4>
        </b-col>
        <b-col class="d-flex flex-column flex-fill justify-content-center align-items-end" cols="12" md="4">
          <b-card no-body>
            <p class="mb-0 p-3"><strong>Total Payments:</strong> {{ totalPayments | currencyFormat() }}</p>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th>Type</b-th>
                <b-th>Customer</b-th>
                <b-th>Order ID</b-th>
                <b-th>Date</b-th>
                <b-th>Method</b-th>
                <b-th>Amount</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(reportItem, reportItemInd) in paymentRecordsArray"
                    :key="reportItemInd">

                <b-td v-if="reportItem.type === 'Refund'" class="danger">{{ reportItem.type }}</b-td>
                <b-td v-else>{{ reportItem.type }}</b-td>

                <b-td v-if="reportItem.type === 'Refund'" class="danger">
                  {{ reportItem.customer ? reportItem.customer.name : '-' }}
                </b-td>
                <b-td v-else>{{ reportItem.customer ? reportItem.customer.name : '-' }}</b-td>

                <b-td v-if="reportItem.type === 'Refund'" class="danger">
                  <router-link :to="{name: 'dash.orders.view', params: {id: reportItem.order_id}}">
                    {{ reportItem.order_id }}
                  </router-link>
                </b-td>
                <b-td v-else>
                  <router-link :to="{name: 'dash.orders.view', params: {id: reportItem.order_id}}">
                    {{ reportItem.order_id }}
                  </router-link>
                </b-td>

                <b-td v-if="reportItem.type === 'Refund'" class="danger">
                  {{ moment(reportItem.date).format('Do MMM YYYY') }}
                </b-td>
                <b-td v-else>{{ moment(reportItem.date).format('Do MMM YYYY') }}</b-td>

                <b-td v-if="reportItem.type === 'Refund'" class="danger">{{ reportItem.method }}</b-td>
                <b-td v-else>{{ reportItem.method }}</b-td>

                <b-td v-if="reportItem.type === 'Refund'" class="danger">
                  {{ reportItem.refund_amount | currencyFormat() }}
                </b-td>
                <b-td v-else>{{ reportItem.amount | currencyFormat() }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [momentMixin],
  created() {
    this.selectedStartDate = this.moment().startOf('month').format('YYYY-MM-DD')
    this.selectedEndDate = this.moment().endOf('month').format('YYYY-MM-DD')
  },
  data: () => ({
    method: null,
    selectedStartDate: null,
    selectedEndDate: null,
    totalRevenue: 0,
    paymentRecordsArray: [],
    payments: [],
    refunds: [],
    totalPayments: null,
    totalRefunds: null,
    updating: false
  }),
  computed: {
    valid() {
      return (
        Boolean(this.selectedStartDate) &&
        Boolean(this.selectedEndDate)
      )
    }
  },
  methods: {
    update() {
      if (!this.updating && this.valid) {
        this.updating = true;
        window.axios.post('/reports/payments-report', {
          'start_date': this.selectedStartDate,
          'end_date': this.selectedEndDate,
          'method': this.method
        }).then(response => {
          this.payments = response.data.payments
          this.totalPayments = response.data.totalPayments
          this.refunds = response.data.refunds
          this.totalRefunds = response.data.totalRefunds
          this.paymentRecordsArray = response.data.paymentRecordsArray
          this.updating = false
        })
      }
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style lang="scss">
.amount-card {
  width: 100%;

  p {
    text-align: center;
  }
}

.danger {
  background-color: red;
  color: white;

  a {
    color: white;
  }
}
</style>

<template>
  <div>
    <b-card class="d-print-none">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Start Date">
              <b-form-datepicker v-model="selectedStartDate" reset-button/>
            </b-form-group>
            <b-form-group label="End Date">
              <b-form-datepicker v-model="selectedEndDate" reset-button/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update">
              <b-spinner v-if="updating" small></b-spinner>
              <span v-if="!updating">Update Report</span></b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card v-if="payments.length" class="mt-4">
      <b-row class="mb-2">
        <b-col class="d-flex flex-column flex-fill justify-content-center" cols="12" md="4">
          <h4 class="m-0">Accountant Report</h4>
        </b-col>
        <b-col class="d-flex flex-column justify-content-center align-items-end" cols="12" md="3">
          <b-card no-body>
            <a :href="exportReportCSVURL()" class="btn btn-primary">Export CSV</a>
          </b-card>
        </b-col>
        <b-col class="d-flex flex-column justify-items-center align-items-end" cols="12" md="5">
          <b-card no-body>
            <p class="mb-0 p-3"><strong>Total Order Value:</strong> {{ totalInvoiceValue | currencyFormat }}</p>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th>Order ID</b-th>
                <b-th class="text-center">Customer</b-th>
                <b-th class="text-center">Amount</b-th>
                <b-th class="text-right">Total Payments</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(order, paymentInd) in payments" :key="paymentInd">
                <b-td>
                  <router-link v-b-popover="'View Order.'"
                               :to="{ name: 'dash.order.view', params: { id: order.id } }">#{{ order.id }}
                  </router-link>
                </b-td>
                <b-td class="text-center">{{ order.customer.name }}</b-td>
                <b-td class="text-center">{{ order.total_price | currencyFormat }}</b-td>
                <b-td class="text-right">{{ order.total_payments | currencyFormat }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import momentMixin from "../../../mixins/momentMixin"

export default {
  mixins: [momentMixin],
  created() {
    this.selectedStartDate = this.moment()
      .startOf("month")
      .format("YYYY-MM-DD")
    this.selectedEndDate = this.moment()
      .endOf("month")
      .format("YYYY-MM-DD")
  },
  data: () => ({
    selectedStartDate: null,
    selectedEndDate: null,
    totalOrderPayments: 0,
    totalInvoiceValue: 0,
    payments: [],
    customer: [],
    order: [],
    updating: false
  }),

  computed: {
    valid() {
      return Boolean(this.selectedStartDate) && Boolean(this.selectedEndDate)
    }
  },
  methods: {
    update() {
      if (!this.updating && this.valid) {
        this.updating = true
        window.axios
          .post("/reports/accountant-report", {
            start_date: this.selectedStartDate,
            end_date: this.selectedEndDate
          })
          .then(response => {
            this.payments = response.data.payments
            this.customer = response.data.customer
            this.totalOrderPayments = response.data.totalOrderPayments
            this.totalInvoiceValue = response.data.totalInvoiceValue
            this.updating = false
          })
      }
    },
    exportReportCSVURL() {
      if (this.selectedStartDate && this.selectedEndDate) {
        return `api/export/reports?start_date=${this.selectedStartDate}&end_date=${this.selectedEndDate}`
      }
      return null
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<template>
  <b-container fluid>

    <b-row class="mb-4">
      <b-col class="mb-2 mb-md-0" cols="12" md="4">
        <h1 class="font-weight-bold mb-0 text-primary">Reports</h1>
        <b-button class="d-print-none ml-0 pl-0" variant="link" @click="doPrint()">Print</b-button>
      </b-col>
      <b-col class="d-md-none mb-2" cols="12">
        <b-form-select v-model="selectedReportType">
          <b-form-select-option :value="null">Please select a report to generate</b-form-select-option>
          <b-form-select-option v-for="(reportType, ind) in authenticatedReports" :key="ind"
                                :value="reportType.component">{{ reportType.text }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
      <b-col class="d-flex flex-row align-items-center justify-content-start justify-content-md-end" cols="12" md="8">
        <b-form-select v-model="selectedReportType" class="d-none d-md-block">
          <b-form-select-option :value="null">Please select a report to generate</b-form-select-option>
          <b-form-select-option v-for="(reportType, ind) in authenticatedReports" :key="ind"
                                :value="reportType.component">{{ reportType.text }}
          </b-form-select-option>
        </b-form-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <component :is="selectedReportType" v-if="selectedReportType"/>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import currentUser from '../../mixins/currentUser'
import {mapGetters} from 'vuex'

// Report Components
import OrdersReport from './ReportTypes/OrdersReport';
import QuotesReport from './ReportTypes/QuotesReport';
import PopularProductsReport from './ReportTypes/PopularProductsReport';
import InventoryReport from './ReportTypes/InventoryReport';
import ProfitAndMarginReport from './ReportTypes/ProfitAndMarginReport';
import PaymentsReport from './ReportTypes/PaymentsReport';
import AccountantReport from './ReportTypes/AccountantReport';
import UnfittedOrdersReport from './ReportTypes/UnfittedOrdersReport';
import ReturnsReport from './ReportTypes/ReturnsReport';
import OutstandingSupplyOrdersReport from './ReportTypes/OutstandingSupplyOrdersReport';
import SourceReport from './ReportTypes/SourceReport';
import DiscardedReport from './ReportTypes/DiscardedReport';
import CashUpReport from './ReportTypes/CashUpReport';
import StockAdjustmentReport from './ReportTypes/StockAdjustmentReport';
import OutstandingBalanceReport from './ReportTypes/OutstandingBalanceReport';
import OutstandingBalanceReportGoodsIn from './ReportTypes/OutstandingBalanceReportGoodsIn';

export default {
  mixins: [currentUser],
  data: () => ({
    selectedReportType: null,
    reportTypes: [
      {component: 'accountant-report', text: 'Accountant Report', permissions: ['access-accountant-report']},
      {component: 'cash-up-report', text: 'Cash Up Report', permissions: ['all-reports']},
      {component: 'discarded-report', text: 'Discarded Report', permissions: ['all-reports']},
      {component: 'inventory-report', text: 'Inventory Report', permissions: ['all-reports']},
      {component: 'orders-report', text: 'Orders Report', permissions: ['all-reports']},
      {component: 'outstanding-balance-report', text: 'Outstanding Balance Report', permissions: ['all-reports']},
      {
        component: 'outstanding-balance-report-goods-in',
        text: 'Outstanding Balance Report (Goods In)',
        permissions: ['all-reports']
      },
      {
        component: 'outstanding-supply-orders-report',
        text: 'Outstanding Supply Orders Report',
        permissions: ['all-reports']
      },
      {component: 'payments-report', text: 'Payments Report', permissions: ['all-reports']},
      {component: 'popular-products-report', text: 'Popular Products Report', permissions: ['all-reports']},
      {component: 'profit-and-margin-report', text: 'Profit and Margin Report', permissions: ['all-reports']},
      {component: 'quotes-report', text: 'Quotes Report', permissions: ['all-reports']},
      {component: 'returns-report', text: 'Returns Report', permissions: ['all-reports']},
      {component: 'source-report', text: 'Sources Report', permissions: ['all-reports']},
      {component: 'stock-adjustment-report', text: 'Stock Adjustment Report', permissions: ['all-reports']},
      {component: 'unfitted-orders-report', text: 'Unfitted Orders Report', permissions: ['all-reports']},
    ],
  }),
  components: {
    // Reports
    AccountantReport,
    CashUpReport,
    DiscardedReport,
    InventoryReport,
    OrdersReport,
    OutstandingBalanceReport,
    OutstandingBalanceReportGoodsIn,
    OutstandingSupplyOrdersReport,
    PaymentsReport,
    PopularProductsReport,
    ProfitAndMarginReport,
    QuotesReport,
    ReturnsReport,
    SourceReport,
    StockAdjustmentReport,
    UnfittedOrdersReport,
  },
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),

    authenticatedReports() {
      return this.reportTypes.filter(reportType => (this.hasAnyPermission(reportType.permissions)))
    },
  },
  methods: {
    doPrint() {
      window.print()
    }
  }
}
</script>


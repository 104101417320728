<template>
  <div>
    <b-card class="d-print-none">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Start Date">
              <b-form-datepicker v-model="selectedStartDate" reset-button/>
            </b-form-group>
            <b-form-group label="End Date">
              <b-form-datepicker v-model="selectedEndDate" reset-button/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Product">
              <ProductSearch v-model="filters.product" type="products"/>
            </b-form-group>
            <b-form-group label="Category">
              <b-form-select v-model="filters.category">
                <b-form-select-option :value="null">Select a category</b-form-select-option>
                <b-form-select-option v-for="category in filters.categories" :key="`category${category.id}`"
                                      :value="category.id">{{ category.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              block
              class="align-self-end mt-3"
              type="button"
              variant="primary"
              @click="update">
              <b-spinner v-if="updating" small></b-spinner>
              <span v-if="!updating">Update Report</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card v-if="stockAdjustments.length" class="mt-4">
      <b-row>
        <b-col cols="12">
          <h4>Stock Adjustment Report</h4>
          <hr>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th>Product</b-th>
                <b-th>Item Cost</b-th>
                <b-th>Item Total</b-th>
                <b-th>Actioned By</b-th>
                <b-th>Actioned At</b-th>
                <b-th>Quantity/Length Before</b-th>
                <b-th>Quantity/Length After</b-th>
                <b-th>Reason</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <tr v-for="(stockAdjustment, stockAdjustmentInd) in stockAdjustments"
                  :key="`adjustment-${stockAdjustmentInd}`">
                <b-td>{{ stockAdjustment.product_name }} - {{ stockAdjustment.product_variation_name }}</b-td>
                <b-td v-if="stockAdjustment.product">
                  {{ stockAdjustment.product.cost_price | currencyFormat() }}
                </b-td>
                <b-td v-else>-</b-td>
                <b-td v-if="stockAdjustment.product">
                  {{ getTotalAmount(stockAdjustment) | currencyFormat() }}
                </b-td>
                <b-td v-else>-</b-td>
                <b-td>{{ stockAdjustment.user.name }}</b-td>
                <b-td>{{ moment(stockAdjustment.actioned_at).format("DD/MM/YYYY HH:mm") }}</b-td>
                <b-td>{{ stockAdjustment.quantity_length_before }}</b-td>
                <b-td>{{ stockAdjustment.quantity_length_after }}</b-td>
                <b-td>{{ stockAdjustment.reason }}</b-td>
              </tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import momentMixin from "../../../mixins/momentMixin";
import ProductSearch from "../../../components/ProductSearch";
import productModule from "@/store/products";
import categoryModule from "@/store/categories";
import orderModule from "@/store/orders";
import {mapActions} from "vuex";

export default {
  mixins: [momentMixin],

  components: {
    ProductSearch
  },
  beforeCreate() {
    this.$store.registerModule("products", productModule);
    this.$store.registerModule("categories", categoryModule);
    this.$store.registerModule("orders", orderModule);
  },
  beforeDestroy() {
    this.$store.unregisterModule("products");
    this.$store.unregisterModule("categories");
    this.$store.unregisterModule("orders");
  },
  created() {
    this.selectedStartDate = this.moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    this.selectedEndDate = this.moment()
      .endOf("month")
      .format("YYYY-MM-DD");
  },

  mounted() {
    this.fetchManyCategories().then(response => {
      this.filters.categories = response.data;
    });
  },
  data: () => ({
    selectedStartDate: null,
    selectedEndDate: null,
    stockAdjustments: [],
    product: {},
    updating: false,
    product_name: null,
    orders: {},

    filters: {
      product: {},
      category: null,
      categories: [],
    }
  }),
  computed: {
    valid() {
      return Boolean(this.selectedStartDate) && Boolean(this.selectedEndDate);
    }
  },
  methods: {
    ...mapActions("categories", {fetchManyCategories: "fetchAll"}),

    getTotalAmount(stockAdjustment) {
      const beforeQuantity = stockAdjustment.quantity_length_before;
      const afterQuantity = stockAdjustment.quantity_length_after;
      const productCost = stockAdjustment.product.cost_price;
      return ((Number(afterQuantity) - Number(beforeQuantity)) * Number(productCost))
    },
    update() {
      if (!this.updating && this.valid) {
        this.updating = true;
        this.stockAdjustments = [];

        window.axios
          .post("/reports/stock-adjustments", {
            start_date: this.selectedStartDate,
            end_date: this.selectedEndDate,
            product: this.filters.product.id,
            category: this.filters.category
          })
          .then(response => {
            this.stockAdjustments = response.data;
            this.updating = false;
          });
      }
    }
  },
  filters: {
    currencyFormat: v =>
      new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP"
      }).format(v)
  }
};
</script>

<template>
  <div>
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update">
            <b-spinner v-if="updating" small></b-spinner>
            <span v-if="!updating">Update Report</span></b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-card v-if="orders.length" class="mt-4">
      <b-row class="mb-2">
        <b-col class="d-flex flex-column flex-fill justify-content-center" cols="12" md="8">
          <h4 class="m-0">Unfitted Orders Report</h4>
        </b-col>
        <b-col class="d-flex flex-column justify-content-center align-items-end" cols="12" md="4">
          <b-card no-body>
            <p class="mb-0 p-3"><strong>Total Unfitted Orders:</strong> {{ totalUnfittedOrders }}</p>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="accordion" role="tablist">
            <b-card v-for="(order, orderInd) in orders" :key="orderInd" class="mb-1" no-body>
              <b-card-header v-b-toggle="`accordion-${orderInd}`" class="p-3" header-tag="header" role="button">
                <b-row>
                  <b-col class="d-flex flex-column flex-fill justify-content-center" cols="12" md="8">
                    <p class="h5 mb-0">
                      <router-link v-b-popover="'View Order.'"
                                   :to="{name: 'dash.orders.view', params: { id: order.id }}">Order #{{ order.id }}
                      </router-link>
                    </p>
                    <small class="text-muted pt-2">
                      <router-link v-b-popover="'View Customer.'"
                                   :to="{name: 'dash.customers.view', params: { id: order.customer_id }}"
                                   class="text-muted">
                        {{ order.customer.name }}
                      </router-link>
                    </small>
                  </b-col>
                  <b-col class="d-flex flex-column justify-items-center align-items-end" cols="12" md="4">
                    <b-card no-body>
                      <p class="mb-0 p-3"><strong>Order Total:</strong> £{{ order.total_price }}</p>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-collapse :id="`accordion-${orderInd}`" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-table-simple>
                    <b-thead>
                      <b-tr>
                        <b-th>Product</b-th>
                        <b-th class="text-center">Customer</b-th>
                        <b-th class="text-center">Item Total</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(orderItem, orderItemInd) in order.order_items"
                            :key="`order-${orderInd}-item-${orderItemInd}`">
                        <b-td>{{ orderItem.name }}</b-td>
                        <b-td class="text-center">{{ order.customer.name }}</b-td>
                        <b-td class="text-center">{{ orderItem.total_price | currencyFormat }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [momentMixin],
  created() {
    this.selectedStartDate = this.moment().startOf('month').format('YYYY-MM-DD')
    this.selectedEndDate = this.moment().endOf('month').format('YYYY-MM-DD')
  },
  data: () => ({
    selectedStartDate: null,
    selectedEndDate: null,
    totalUnfittedOrders: 0,
    orders: [],
    order_items: [],
    customer: {},
    updating: false,

    order: {
      order_items: [],
      customer: {}
    }
  }),
  computed: {
    valid() {
      return (
        Boolean(this.selectedStartDate) &&
        Boolean(this.selectedEndDate)
      )
    }
  },
  methods: {
    update() {
      if (!this.updating && this.valid) {
        this.updating = true;
        window.axios.post('/reports/unfitted-orders-report', {
          'start_date': this.selectedStartDate,
          'end_date': this.selectedEndDate
        }).then(response => {
          this.orders = response.data.orders
          this.totalUnfittedOrders = response.data.totalUnfittedOrders
          this.updating = false
        })
      }
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>
</style>

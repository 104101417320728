<template>
  <div>
    <b-card class="d-print-none">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Start Date">
              <b-form-datepicker v-model="selectedStartDate" reset-button/>
            </b-form-group>
            <b-form-group label="End Date">
              <b-form-datepicker v-model="selectedEndDate" reset-button/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-button
              block
              class="align-self-end mt-3"
              type="button"
              variant="primary"
              @click="update">
              <b-spinner v-if="updating" small></b-spinner>
              <span v-if="!updating">Update Report</span></b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card class="mt-4">
      <b-row class="mb-3">
        <b-col class="d-flex flex-column flex-fill justify-content-center" cols="12" md="6">
          <h4 class="m-0">Outstanding Supply Orders</h4>
        </b-col>
        <b-col class="d-flex flex-column justify-items-center align-items-end" cols="12" md="6">
          <b-card no-body>
            <p class="mb-0 p-3"><strong>Total Outstanding Supply Orders:</strong>{{ totalOutstandingSupplyOrders }}</p>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="accordion" role="tablist">
            <b-card class="mb-1" no-body>
              <b-table-simple>
                <b-thead>
                  <b-tr>
                    <b-th>Product</b-th>
                    <b-th>Length / Quantity</b-th>
                    <b-th>Supply Order</b-th>
                    <b-th class="text-right">Supplier</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody
                  v-for="(supplyOrderItem, supplyOrderItemInd) in outstanding"
                  :key="supplyOrderItemInd"
                >
                  <b-tr>
                    <b-td>{{ supplyOrderItem.product ? supplyOrderItem.product.name : supplyOrderItem.name }}
                      <span v-if="supplyOrderItem.variation"> - {{ supplyOrderItem.variation.name }}</span></b-td>
                    <b-td v-if="supplyOrderItem.measurement_types_id === 1">{{ supplyOrderItem.length }}m</b-td>
                    <b-td v-else>{{ supplyOrderItem.quantity }}</b-td>
                    <b-td>
                      <router-link
                        :to="{
                          name: 'dash.supply_orders.view',
                          params: { id: supplyOrderItem.supply_order_id }
                        }"
                        class="text-dark">#{{ supplyOrderItem.supply_order_id }}
                      </router-link>
                    </b-td>
                    <b-td class="text-right">
                      {{ supplyOrderItem.product ? supplyOrderItem.product.supplier.name : "N/A" }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import momentMixin from "../../../mixins/momentMixin";

export default {
  mixins: [momentMixin],
  created() {
    this.selectedStartDate = this.moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    this.selectedEndDate = this.moment()
      .endOf("month")
      .format("YYYY-MM-DD");
  },
  data: () => ({
    selectedStartDate: null,
    selectedEndDate: null,
    totalOutstandingSupplyOrders: 0,
    outstanding: [],
    order_items: [],
    customer: {},
    updating: false,

    order: {
      order_items: [],
      customer: {}
    }
  }),
  computed: {
    valid() {
      return Boolean(this.selectedStartDate) && Boolean(this.selectedEndDate);
    }
  },
  methods: {
    update() {
      if (!this.updating && this.valid) {
        this.updating = true;
        window.axios
          .post("/reports/outstanding-supply-orders-report", {
            start_date: this.selectedStartDate,
            end_date: this.selectedEndDate
          })
          .then(response => {
            this.outstanding = response.data.outstanding;
            this.totalOutstandingSupplyOrders =
              response.data.totalOutstandingSupplyOrders;
            this.updating = false;
          });
      }
    }
  }
};
</script>

<template>
  <div>
    <b-card class="d-print-none">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Start Date">
              <b-form-datepicker v-model="selectedStartDate" reset-button/>
            </b-form-group>
            <b-form-group label="End Date">
              <b-form-datepicker v-model="selectedEndDate" reset-button/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update">
              <b-spinner v-if="updating" small></b-spinner>
              <span v-if="!updating">Update Report</span></b-button>
          </b-col>
        </b-row>

      </b-form>
    </b-card>

    <b-card v-if="returns.length" class="mt-4">
      <b-row>
        <b-col cols="12">
          <b-table-simple>
            <b-thead>
              <b-th>Supply Order ID</b-th>
              <b-th>Product</b-th>
              <b-th class="text-center">Length / Quantity</b-th>
              <b-th class="text-center">Reference</b-th>
              <b-th class="text-center">Reason</b-th>
              <b-th class="text-right">Date</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(item, itemInd) in returns" :key="itemInd">
                <b-td class="text-left">
                  <router-link
                    :to="{name: 'dash.supply_orders.view', params: { id: item.supply_order_items.supply_order_id }}">
                    {{ item.supply_order_items.supply_order_id }}
                  </router-link>
                </b-td>
                <b-td class="text-left">{{ item.product.name }}</b-td>
                <b-td class="text-center">
                  {{ item.supply_order_item_lot_id ? item.supply_order_item_lot.length + 'm' : item.quantity }}
                </b-td>
                <b-td class="text-center">{{ item.reference }}</b-td>
                <b-td class="text-center">{{ item.note }}</b-td>
                <b-td class="text-right">{{ moment(item.created_at).format('DD-MM-YYYY') }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [momentMixin],
  created() {
    this.selectedStartDate = this.moment().startOf('month').format('YYYY-MM-DD')
    this.selectedEndDate = this.moment().endOf('month').format('YYYY-MM-DD')
  },
  data: () => ({
    selectedStartDate: null,
    selectedEndDate: null,
    totalCategories: 0,
    returns: [],
    products: [],
    updating: false,
    supply_order_item_lot_id: null,
  }),
  computed: {
    valid() {
      return (
        Boolean(this.selectedStartDate) &&
        Boolean(this.selectedEndDate)
      )
    }
  },
  methods: {
    update() {
      if (!this.updating && this.valid) {
        this.updating = true;
        window.axios.post('/reports/returns-report', {
          'start_date': this.selectedStartDate,
          'end_date': this.selectedEndDate
        }).then(response => {
          this.products = response.data.products
          this.returns = response.data.returns
          this.updating = false
        })
      }
    }
  }
}
</script>

<style>
</style>

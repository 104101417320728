<template>
  <div>
    <b-card class="d-print-none">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Start Date">
              <b-form-datepicker v-model="selectedStartDate" reset-button/>
            </b-form-group>
            <b-form-group label="End Date">
              <b-form-datepicker v-model="selectedEndDate" reset-button/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-button
              block
              class="align-self-end mt-3"
              type="button"
              variant="primary"
              @click="update">
              <b-spinner v-if="updating" small></b-spinner>
              <span v-if="!updating">Update Report</span></b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card class="mt-4">
      <b-row class="mb-2">
        <b-col class="d-flex flex-column flex-fill" cols="12" md="9">
          <h4 class="m-0">Outstanding Balance Report (Goods In)</h4>
        </b-col>
        <b-col class="d-flex flex-column justify-content-center align-items-end" cols="12" md="3">
          <b-card no-body>
            <p class="m-0 p-3"><strong>Total: </strong>{{ totalOutstandingBalance | currencyFormat }}</p>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th>Order ID</b-th>
                <b-th>Customer Name</b-th>
                <b-th>First Fitting Date</b-th>
                <b-th>Order Total</b-th>
                <b-th>Payments Total</b-th>
                <b-th>Total Outstanding</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(order, orderInd) in orders"
                     :key="orderInd">
              <b-tr>
                <b-td>
                  <router-link :to=" {
                    name: 'dash.orders.view',
                    params: {id: order.id}
                  }">{{ order.id }}
                  </router-link>
                </b-td>
                <b-td>{{ order.customer.name }}</b-td>
                <b-td v-if="order.order_items[0].fitting_date">
                  {{ moment(order.order_items[0].fitting_date).format("Do MMM YYYY") }}
                </b-td>
                <b-td v-else>No Fitting Specified</b-td>
                <b-td>{{ removeSubsidyFromTotal(order) | currencyFormat }}</b-td>
                <b-td>{{ order.totalPayments | currencyFormat }}</b-td>
                <b-td>{{ getRemainingBalance(order) | currencyFormat }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import momentMixin from "../../../mixins/momentMixin";

export default {
  mixins: [momentMixin],
  created() {
    this.selectedStartDate = this.moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    this.selectedEndDate = this.moment()
      .endOf("month")
      .format("YYYY-MM-DD");
  },
  data: () => ({
    selectedStartDate: null,
    selectedEndDate: null,
    updating: false,
    totalOutstandingBalance: null,
    totalOrderAmount: null,
    orders: [],
    paymentTotals: [],
  }),
  computed: {
    valid() {
      return Boolean(this.selectedStartDate) && Boolean(this.selectedEndDate);
    }
  },
  methods: {
    removeSubsidyFromTotal(order) {
      if (order.subsidy) {
        return Number(Number(order.total_price) - Number(order.subsidy));
      }
      return Number(order.total_price)
    },

    getRemainingBalance(order) {
      return (Number(order.total_price) - Number(order.totalPayments));
    },
    
    update() {
      if (!this.updating && this.valid) {
        this.updating = true;
        window.axios
          .post("/reports/outstanding-balance-report-goods-in", {
            start_date: this.selectedStartDate,
            end_date: this.selectedEndDate
          })
          .then(response => {
            this.totalOutstandingBalance = response.data.totalOutstandingBalance;
            this.outstandingBalanceReport = response.data.outstandingBalanceReport;
            this.orders = response.data.orders;
            this.paymentTotals = response.data.paymentTotals
            this.updating = false;
          });
      }
    }
  },
  filters: {
    currencyFormat: v =>
      new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP"
      }).format(v)
  }
};
</script>

<template>
  <div>
    <b-card class="d-print-none">
      <b-form>
        <b-row>
          <b-col>
            <b-form-group label="Product">
              <ProductSearch v-model="filters.product" type="products"/>
            </b-form-group>
            <b-form-group label="Category">
              <b-form-select v-model="filters.category">
                <b-form-select-option :value="null">Select a category</b-form-select-option>
                <b-form-select-option v-for="category in filters.categories" :key="`category${category.id}`"
                                      :value="category.id">{{ category.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button block class="align-self-end" type="button" variant="primary" @click="update">
              <b-spinner v-if="updating" small></b-spinner>
              <span v-if="!updating">Update Report</span></b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card class="mt-4">
      <b-row class="mb-2">
        <b-col class="d-flex flex-column flex-fill justify-content-center" cols="12" md="8">
          <h4 class="m-0">Inventory Report</h4>
        </b-col>
        <b-col class="d-flex flex-column justify-content-center align-items-end" cols="12" md="4">
          <b-card no-body>
            <p class="mb-0 p-3"><strong>Total Cost: {{ calculateInventoryTotalPrice(supplyOrderItem) | currencyFormat()
              }}</strong></p>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table-simple responsive>
            <b-thead>
              <b-tr>
                <b-th>Total Item Cost</b-th>
                <b-th>Item</b-th>
                <b-th>Order ID</b-th>
                <b-th>On Supply Order</b-th>
                <b-th>Physical Stock</b-th>
                <b-th>Stock To Ship</b-th>
                <b-th>Pending Returns (RTS)</b-th>
                <b-th>Completed Returns (RTS)</b-th>
                <b-th>Free Stock</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-for="(supplyOrderItem, supplyOrderItemInd) in supplyOrderItems"
                     :key="`supply-order-item-${supplyOrderItemInd}`">
              <b-tr v-if="displayItem(supplyOrderItem)">
                <b-td>
                  {{ calculateTotalPrice(supplyOrderItem) | currencyFormat() }}
                </b-td>
                <b-td>
                  <span v-if="supplyOrderItem.supply_order_item.product">
                    {{ supplyOrderItem.supply_order_item.product.name }}
                  </span>

                  <span v-else>{{ supplyOrderItem.supply_order_item.name }}</span>

                  <span
                    v-if="supplyOrderItem.supply_order_item.variation">
                    - {{ supplyOrderItem.supply_order_item.variation.name }}
                  </span>
                  <small
                    v-if=" supplyOrderItem.supply_order_item.product && supplyOrderItem.supply_order_item.product.range">
                    <br/>Range: {{ supplyOrderItem.supply_order_item.product.range }}
                  </small>
                </b-td>

                <b-td>
                  <span v-if="!supplyOrderItem.supply_order.order_id" class="text-dark">-</span>
                  <span v-else>
                    <router-link
                      :to="{ name: 'dash.orders.view',params: { id: supplyOrderItem.supply_order.order_id }}"
                      class="text-dark">#{{ supplyOrderItem.supply_order.order_id }}
                      </router-link>
                  </span>
                </b-td>

                <b-td>
                  <router-link
                    :to="{name: 'dash.supply_orders.view', params: {id: supplyOrderItem.supply_order_id}}">Supply Order
                    #{{ supplyOrderItem.supply_order_id }} -
                    {{ supplyOrderItem.supply_order.name }}
                  </router-link>
                </b-td>
                <b-td>
                  {{ Number(supplyOrderItem.physical_stock).toFixed(2)
                  }}{{ supplyOrderItem.measurement_types_id === 1 ? "m" : "" }}
                </b-td>
                <b-td>{{ Number(supplyOrderItem.stock_to_ship).toFixed(2)
                  }}{{ supplyOrderItem.measurement_types_id === 1 ? "m" : "" }}
                </b-td>
                <b-td>
                  {{ Number(supplyOrderItem.pending_returns).toFixed(2)
                  }}{{ supplyOrderItem.measurement_types_id === 1 ? "m" : "" }}
                </b-td>
                <b-td>
                  {{ Number(supplyOrderItem.completed_returns).toFixed(2)
                  }}{{ supplyOrderItem.measurement_types_id === 1 ? "m" : "" }}
                </b-td>
                <b-td>
                  {{ Number(supplyOrderItem.free_stock).toFixed(2) }}
                  {{ supplyOrderItem.measurement_types_id === 1 ? "m" : "" }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import momentMixin from "../../../mixins/momentMixin";
import ProductSearch from "../../../components/ProductSearch";
import {mapActions} from "vuex";

import productModule from "../../../store/products";
import categoryModule from "../../../store/categories";

export default {
  mixins: [momentMixin],
  components: {ProductSearch},
  beforeCreate() {
    this.$store.registerModule("products", productModule);
    this.$store.registerModule("categories", categoryModule);
  },
  beforeDestroy() {
    this.$store.unregisterModule("products");
    this.$store.unregisterModule("categories");
  },
  data: () => ({
    supplyOrderItems: [],
    total: null,
    physical_stock: null,
    stock_to_ship: null,
    pending_returns: null,
    free_stock: null,
    updating: false,
    supply_order: [],
    completed_returns: null,

    filters: {
      product: {id: null},
      category: null,
      categories: []
    }
  }),
  mounted() {
    this.fetchManyCategories().then(response => {
      this.filters.categories = response.data;
    });
  },
  methods: {
    ...mapActions("categories", {fetchManyCategories: "fetchAll"}),

    displayItem(supplyOrderItem) {
      return (supplyOrderItem.physical_stock > 0 || supplyOrderItem.stock_to_ship > 0 || supplyOrderItem.pending_returns > 0 || supplyOrderItem.free_stock > 0
      );
    },

    calculateTotalPrice(supplyOrderItem) {
      return Number(supplyOrderItem.supply_order_item.product.cost_price) * supplyOrderItem.physical_stock;
    },

    calculateInventoryTotalPrice() {
      let total = 0.00;

      this.supplyOrderItems.forEach(function (supplyOrderItem) {
        total += Number(supplyOrderItem.supply_order_item.product.cost_price) * supplyOrderItem.physical_stock;
      });

      return total;
    },

    update() {
      if (!this.updating) {
        this.updating = true;
        window.axios
          .post("/reports/inventory-report", {
            product: this.filters.product.id,
            category: this.filters.category
          })
          .then(response => {
            this.supplyOrderItems = response.data;
            this.updating = false;
          });
      }
    }
  },
  filters: {
    currencyFormat: v =>
      new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP"
      }).format(v)
  }
};
</script>


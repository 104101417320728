<template>
    <div>
        <b-card class="d-print-none">
            <b-form>
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Start Date">
                            <b-form-datepicker reset-button v-model="selectedStartDate" />
                        </b-form-group>
                        <b-form-group label="End Date">
                            <b-form-datepicker reset-button v-model="selectedEndDate" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update"><b-spinner small v-if="updating"></b-spinner><span v-if="!updating">Update Report</span></b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>

        <b-card class="mt-4" v-if="discardedLots.length">
            <b-row class="mb-2">
                <b-col cols="12" class="d-flex flex-row">
                    <h4 class="m-0 flex-fill">Discarded Report</h4>
                </b-col>
            </b-row>
            <b-row v-if="discardedLots.length">
                <b-col><hr></b-col>
            </b-row>
            <b-row v-if="discardedLots.length">
                <b-col cols="12">
                    <b-table-simple sticky-header>
                        <b-thead>
                            <b-tr>
                                <b-th>Lot ID</b-th>
                                <b-th>Supply Order ID</b-th>
                                <b-th>Product</b-th>
                                <b-th>Product Variation</b-th>
                                <b-th>Length</b-th>
                                <b-th>Discarded At</b-th>
                                <b-th>Discarded By</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(discardedLot, discardedLotInd) in discardedLots" :key="discardedLotInd">
                                <b-td>#{{ discardedLot.id }}</b-td>
                                <b-td>#{{ discardedLot.supply_order_item.supply_order_id }}</b-td>
                                <b-td>{{ discardedLot.supply_order_item.product.name }}</b-td>
                                <b-td>{{ discardedLot.supply_order_item.product_variation.name }}</b-td>
                                <b-td>{{ discardedLot.length }}</b-td>
                                <b-td>{{ moment(discardedLot.discarded_at).format('DD/MM/YYYY HH:mm') }}</b-td>
                                <b-td>{{ discardedLot.discarded_by_user.name }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [ momentMixin ],
  created() {
      this.selectedStartDate = this.moment().startOf('month').format('YYYY-MM-DD')
      this.selectedEndDate = this.moment().endOf('month').format('YYYY-MM-DD')
  },
  data: () => ({
      selectedStartDate: null,
      selectedEndDate: null,
      discardedLots: [],
      updating: false
  }),
  computed: {
      valid() {
          return (
              Boolean(this.selectedStartDate) &&
              Boolean(this.selectedEndDate)
          )
      },
  },
  methods: {

    update() {
        if(!this.updating && this.valid) {
            this.updating = true;
            window.axios.post('/reports/discarded-report', {
                'start_date': this.selectedStartDate,
                'end_date': this.selectedEndDate,
            }).then(response => {
                this.discardedLots = response.data.discarded
                this.updating = false
            })
        }
    },
  }
}
</script>

<style>
</style>

<template>
  <div>
    <b-card class="d-print-none">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Start Date">
              <b-form-datepicker v-model="selectedStartDate" reset-button/>
            </b-form-group>
            <b-form-group label="End Date">
              <b-form-datepicker v-model="selectedEndDate" reset-button/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update">
              <b-spinner v-if="updating" small></b-spinner>
              <span v-if="!updating">Update Report</span></b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card v-if="orders.length" class="mt-4">
      <b-row class="mb-2">
        <b-col cols="12">
          <div class="d-flex flex-row align-items-center">
            <div class="flex-fill">
              <h4 class="m-0">Profit and Margin Report</h4>
            </div>
            <b-card class="mr-2" no-body>
              <p class="mb-0 p-3"><strong>Total Profit:</strong> {{ totalProfit | currencyFormat }}</p>
            </b-card>
            <b-card no-body>
              <p class="mb-0 p-3"><strong>Profit Percentage:</strong> {{ profitPercentage(salePriceTotal, totalProfit)
                }}%</p>
            </b-card>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="accordion" role="tablist">
            <b-card v-for="(order, orderInd) in orders" :key="orderInd" no-body>
              <b-card-header v-b-toggle="`accordion-${orderInd}`" class="p-3" header-tag="header" role="tab">
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-row align-items-center">
                      <div class="flex-fill">
                        <router-link v-b-popover="'View Order.'"
                                     :to="{ name: 'dash.orders.view', params: { id: order.id } }"
                                     class="h5">Order #{{ order.id }}
                        </router-link>
                      </div>
                      <b-card class="mr-2" no-body>
                        <p class="mb-0 p-3"><strong>Order Profit:</strong> {{ order.profit | currencyFormat }}</p>
                      </b-card>
                      <b-card no-body>
                        <p class="mb-0 p-3"><strong>Profit Percentage:</strong>
                          {{ profitPercentage(order.total_price, order.profit) }}%</p>
                      </b-card>
                    </div>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-collapse :id="`accordion-${orderInd}`" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-table-simple hover>
                    <b-thead>
                      <b-tr>
                        <b-th>Product</b-th>
                        <b-th class="text-center">Sale Price</b-th>
                        <b-th class="text-center">Cost Price</b-th>
                        <b-th class="text-center">Quantity</b-th>
                        <b-th class="text-right">Profit</b-th>
                        <b-th class="text-right">Profit Percentage</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, itemInd) in order.order_items" :key="itemInd">
                        <b-td>
                          {{ item.name }}
                        </b-td>
                        <b-td class="text-center">{{ item.total_sale_price | currencyFormat }}</b-td>
                        <b-td class="text-center">{{ item.total_cost_price | currencyFormat }}</b-td>
                        <b-td class="text-center">{{ item.quantity }}</b-td>
                        <b-td class="text-right">{{ item.profit | currencyFormat }}</b-td>
                        <b-td class="text-right">{{ profitPercentage(item.total_sale_price, item.profit) }}%</b-td>
                      </b-tr>
                      <b-tr v-if="order.subsidy">
                        <b-td class="text-muted">Subsidy: {{ order.subsidy | currencyFormat }}</b-td>
                        <b-td class="text-center">-</b-td>
                        <b-td class="text-center">-</b-td>
                        <b-td class="text-center">-</b-td>
                        <b-td class="text-right">-</b-td>
                        <b-td class="text-right">-</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import momentMixin from "../../../mixins/momentMixin"

export default {
  mixins: [momentMixin],
  created() {
    this.selectedStartDate = this.moment()
      .startOf("month")
      .format("YYYY-MM-DD")
    this.selectedEndDate = this.moment()
      .endOf("month")
      .format("YYYY-MM-DD")
  },
  data: () => ({
    selectedStartDate: null,
    selectedEndDate: null,

    totalProfit: 0,
    salePriceTotal: 0,

    orders: [],
    order_items: [],
    updating: false,
    profit: null,
  }),
  computed: {
    valid() {
      return Boolean(this.selectedStartDate) && Boolean(this.selectedEndDate)
    },
  },
  methods: {
    update: function () {
      if (!this.updating && this.valid) {
        this.updating = true
        this.totalProfit = 0
        window.axios
          .post("/reports/profit-and-margin-report", {
            start_date: this.selectedStartDate,
            end_date: this.selectedEndDate
          })
          .then(response => {
            this.orders = response.data.orders
            this.order_items = response.data.order_items ?? []
            this.totalProfit = response.data.totalProfit

            this.salePriceTotal = 0
            if (this.orders.length) {
              this.orders.forEach(item => {
                this.salePriceTotal += Number(item.total_price)
              })
            }

            this.updating = false
          })
      }
    },

    profitPercentage(salePrice, profit) {
      if (salePrice) {
        return ((100 * profit) / salePrice).toFixed(2)
      }
      return 0
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>
</style>

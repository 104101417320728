<template>
  <div>
    <b-card class="d-print-none">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Start Date">
              <b-form-datepicker v-model="selectedStartDate" reset-button/>
            </b-form-group>
            <b-form-group label="End Date">
              <b-form-datepicker v-model="selectedEndDate" reset-button/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update">
              <b-spinner v-if="updating" small></b-spinner>
              <span v-if="!updating">Update Report</span></b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <b-card v-if="quotes.length === 0" class="mt-4">
      <b-row>
        <b-col class="d-flex flex-column flex-fill" cols="12" md="9">
          <h4 class="m-0">Sorry, no results found matching your search.</h4>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="quotes.length" class="mt-4">
      <b-row class="mb-2">
        <b-col class="d-flex flex-column flex-fill" cols="12" md="8">
          <h4 class="m-0">Sales Report</h4>
        </b-col>
        <b-col class="d-flex flex-column justify-items-center align-items-end" cols="12" md="4">
          <b-card no-body>
            <p class="mb-0 p-3"><strong>Total Revenue:</strong>{{ totalRevenue | currencyFormat }}</p>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="accordion" role="tablist">
            <b-card v-for="(quote, quoteInd) in quotes" :key="quoteInd" class="mb-3" no-body>
              <b-card-header v-b-toggle="`accordion-${quoteInd}`" class="p-3" header-tag="header" role="button">
                <b-row>
                  <b-col class="d-flex flex-column flex-fill justify-items-center" cols="12" md="8">
                    <p class="h5 mb-0">
                      <router-link v-b-popover="'View Order.'"
                                   :to="{name: 'dash.quotes.view', params: { id: quote.id }}">Quote #{{ quote.id }}
                      </router-link>
                    </p>
                    <small class="text-muted pt-2">
                      <router-link v-b-popover="'View Customer.'"
                                   :to="{name: 'dash.customers.view', params: { id: quote.customer_id }}"
                                   class="text-muted">
                        #{{ quote.customer_id }} {{ quote.customer.name }}
                      </router-link>
                    </small>
                  </b-col>
                  <b-col class="d-flex flex-column justify-items-center align-items-end" cols="12" md="4">
                    <b-card no-body>
                      <p class="mb-0 p-3"><strong>Order Total:</strong>{{ quote.total_price | currencyFormat }}</p>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-collapse :id="`accordion-${quoteInd}`" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-table-simple>
                    <b-thead>
                      <b-tr>
                        <b-th>Product</b-th>
                        <b-th class="text-right">Price</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, itemInd) in quote.order_items" :key="itemInd">
                        <b-td>{{ item.name }}</b-td>
                        <b-td class="text-right">{{ item.total_price | currencyFormat }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [momentMixin],
  created() {
    this.selectedStartDate = this.moment().startOf('month').format('YYYY-MM-DD')
    this.selectedEndDate = this.moment().endOf('month').format('YYYY-MM-DD')
  },
  data: () => ({
    selectedStartDate: null,
    selectedEndDate: null,
    totalRevenue: 0,
    quotes: [],
    customer: {},
    updating: false
  }),
  computed: {
    valid() {
      return (
        Boolean(this.selectedStartDate) &&
        Boolean(this.selectedEndDate)
      )
    }
  },
  methods: {
    update() {
      if (!this.updating && this.valid) {
        this.updating = true;
        window.axios.post('/reports/quotes-report', {
          'start_date': this.selectedStartDate,
          'end_date': this.selectedEndDate
        }).then(response => {
          this.quotes = response.data.quotes
          this.totalRevenue = response.data.totalRevenue
          this.updating = false
        })
      }
    }
  },
  filters: {
    currencyFormat: (v) => new Intl.NumberFormat('en-GB', {
      style: 'currency', currency: 'GBP'
    }).format(v)
  }
}
</script>

<style>
</style>

<template>
    <div>
        <b-card class="d-print-none">
            <b-form>
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Start Date">
                            <b-form-datepicker reset-button v-model="selectedStartDate" />
                        </b-form-group>
                        <b-form-group label="End Date">
                            <b-form-datepicker reset-button v-model="selectedEndDate" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-button block class="align-self-end mt-3" type="button" variant="primary" @click="update"><b-spinner small v-if="updating"></b-spinner><span v-if="!updating">Update Report</span></b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>

        <b-card class="mt-4" v-if="sources.length">
            <b-row class="mb-2">
                <b-col cols="12" class="d-flex flex-row">
                    <h4 class="m-0 flex-fill">Sources Report</h4>
                    <b-card no-body class="mx-2" v-if="reportTotals.quote != null">
                        <p class="mb-0 p-3"><strong>Quotes:</strong> {{ reportTotals.quote }}</p>
                    </b-card>
                    <b-card no-body class="mx-2" v-if="reportTotals.order != null">
                        <p class="mb-0 p-3"><strong>Orders:</strong>  {{ reportTotals.order }}</p>
                    </b-card>
                    <b-card no-body class="mx-2" v-if="reportTotals.all != null">
                        <p class="mb-0 p-3"><strong>All:</strong>  {{ reportTotals.all }}</p>
                    </b-card>
                </b-col>
            </b-row>
            <b-row v-if="sources.length">
                <b-col><hr></b-col>
            </b-row>
            <b-row v-if="sources.length">
                <b-col cols="12">
                    <b-table-simple sticky-header>
                        <b-thead>
                            <b-tr>
                                <b-th>Source</b-th>
                                <b-th>Quotes</b-th>
                                <b-th>Orders</b-th>
                                <b-th>All</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="source in sources" :key="source">
                                <b-td>{{ source }}</b-td>
                                <b-td>{{ reportBreakdown[source].quote }}</b-td>
                                <b-td>{{ reportBreakdown[source].order }}</b-td>
                                <b-td>{{ reportBreakdown[source].all }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import momentMixin from '../../../mixins/momentMixin'

export default {
  mixins: [ momentMixin ],
  created() {
    this.selectedStartDate = this.moment().startOf('month').format('YYYY-MM-DD')
    this.selectedEndDate = this.moment().endOf('month').format('YYYY-MM-DD')
  },
  data: () => ({
    selectedStartDate: null,
    selectedEndDate: null,
    reportBreakdown: { },
    reportTotals: { all: null, order: null, quote: null },
    updating: false
  }),
  computed: {
      valid() {
          return (
              Boolean(this.selectedStartDate) &&
              Boolean(this.selectedEndDate)
          )
      },

      sources() {
          return Object.keys(this.reportBreakdown)
      }
  },
  methods: {
    update() {
        if(!this.updating && this.valid) {
            this.reportBreakdown = {}
            this.reportTotals = { all: null, order: null, quote: null }

            this.updating = true;
            window.axios.post('/reports/source-report', {
                'start_date': this.selectedStartDate,
                'end_date': this.selectedEndDate,
            }).then(response => {
                this.reportTotals = response.data.total
                this.reportBreakdown = response.data.breakdown
                this.updating = false
            })
        }
    }
  }
}
</script>

<style>
</style>
